import {MetaSwiper, SafariSwiper} from "./meta-settings.js";
import Swiper from "https://cdnjs.cloudflare.com/ajax/libs/Swiper/8.4.4/swiper-bundle.esm.browser.js";

function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
if(iOS()) $('body').addClass('iphone-ipad')



const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
    return document.documentElement || document.body;
};

if (header.classList.contains("fixed")) {
    document.addEventListener("scroll", () => {
        if (scrollContainer().scrollTop > 0) {
            header.classList.add("scrolled");
        } else if (scrollContainer().scrollTop == 0) {
            header.classList.remove("scrolled");
        }
    });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
    document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

//fix mobile menu height because of bottom system navigation panel
if(isMobileMenuEnable){
    let navbar = document.querySelector('.navbar-nav');
    if(navbar){
        let vh = window.innerHeight * 0.01;
        navbar.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize',()=>{
            let vh = window.innerHeight * 0.01;
            navbar.style.setProperty('--vh', `${vh}px`);
        })
    }
}
$(".dropdown-toggle").click(function (e) {
    if (isMobileMenuEnable) {
        //close all opened sub menu
        $(".menu-item.dropdown.active .dropdown-menu").slideUp({
            complete: function () {
                $(this).closest(".dropdown").removeClass("active");
            },
        });

        //open current submenu
        $(this).closest(".menu-item.dropdown").toggleClass("active");
        if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
            e.preventDefault();
            $(this).next(".dropdown-menu").slideDown();
        }
    }
});

// toggle menu handler
function menuToggle() {
    $(".menu-toggle").toggleClass("active");
    $(".navbar-nav").toggleClass("active");
    $(".header-close-wrapper").toggleClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    scrollLock.getScrollState()
        ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
        : scrollLock.enablePageScroll();
}
window.closeMenu = function closeMenu(){
    $(".menu-toggle").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".header-close-wrapper").removeClass("active");
    // LockScroll when burger open and enable when closed and enable scroll on menu
    scrollLock.clearQueueScrollLocks();
    scrollLock.enablePageScroll();
}



//menu update function
function updateMenu() {
    isMobileMenuEnable =
        $(window).outerWidth() <= mobileMenuStartPoint ||
        $(".mobile-header").length;
    if (!isMobileMenuEnable) {
        $(".dropdown-menu").css("display", "");
        $(".header-close-wrapper").removeClass("active");
        $(".menu-item.active").removeClass("active");
        $(".navbar-nav").removeClass("active");
        $(".menu-toggle").removeClass("active");
        // LockScroll when burger open and enable when closed
        scrollLock.enablePageScroll();
    }
}

$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

const mapElement = document.getElementById("map");
if (mapElement) {
    console.log(mapElement.querySelectorAll("g"));
}

/*Sets same min-height for same elements*/
function setSameHeight(elArr) {
    let minHeight = 0;
    let arr = document.querySelectorAll(elArr);
    if (arr) {
        function setHeight() {
            arr.forEach(el => {
                if (minHeight < el.clientHeight) {
                    minHeight = el.clientHeight;
                }
            })
            arr.forEach(el => el.style.minHeight = minHeight + 'px')
        }

        window.addEventListener('resize', setHeight())
    }

}

setSameHeight('.values-item-icon');
/* Same min-height end*/

let tsSwiperWr = document.querySelectorAll('.ts-swiper-wr')
tsSwiperWr.forEach(el => {
    if (el) {
        let swiperEl = el.querySelector('.swiper');
        let nextEl = el.querySelector('.swiper-button-next')
        let prevEl = el.querySelector('.swiper-button-prev')
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let swiper = MetaSwiper(swiperEl, {
            slidesPerView: 1,
            spaceBetween: 30,
            speed: 800,
            threshold: 10,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
            },
			effect: 'fade',
			fadeEffect:{
				crossFade: true,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				/*dynamicBullets: true,*/
			},
        })
    }
})

const tilt = $('.btn').tilt({
    perspective: 300,
    disableAxis: 'x',
    glare: true,
    maxGlare: 0.3,
});

function setAnimationMultiplyDelay(element, startDelay, defaultDelayStep, breakDelay) {
    let targetArr = $(`${element}`);
    let delay = startDelay ? startDelay : 0;
    let delayStep = defaultDelayStep ? defaultDelayStep : 0;
    let counter = 0;
    if (!delayStep) return;
    targetArr.each((idx, el) => {
        if (el) {
            $(el).attr('data-aos-delay', delay);
            delay += defaultDelayStep;
            if (breakDelay) {
                counter++
                if (counter === breakDelay) {
                    delay = 0;
                    counter = 0;
                }
            }
        }
    });
}
setAnimationMultiplyDelay('.values-item', 0, 150, 4);

AOS.init({
    duration: 800,
    useClassNames: true,
    once: true,
});